<template>
  <custom-dialog ref="dialog" title="Editar escola" @submit="submit()">
    <v-form v-model="valid" ref="form">
      <div @keypress.enter.prevent="submit()">
        <custom-input
          v-model="form.name"
          ref="name"
          label="Nome da instituição"
        />

        <custom-input
          v-model="form.city"
          ref="city"
          label="Cidade localizada"
        />

        <custom-input
          v-model="form.district"
          ref="district"
          label="Nome do bairro"
        />
      </div>
    </v-form>

    <loader-hover v-if="loading" />
  </custom-dialog>
</template>

<script>
import { updateSchool } from "@/services/league/schools.js";
import { displayAlert } from "@/utils";

export default {
  data() {
    return {
      loading: false,
      valid: false,
      id: "",
      form: {
        name: "",
        city: "",
        district: "",
      },
    };
  },

  methods: {
    async submit() {
      if (!this.$refs.form.validate()) return;

      try {
        this.loading = true;

        const payload = {
          id: this.id,
          body: {
            ...this.form,
          },
        };

        await updateSchool(payload).then(() => {
          this.$emit("success");
          this.close();
        });
      } catch (err) {
        this.displayAlert(err.data.message, 1);
      } finally {
        this.loading = false;
      }
    },

    async handleDialog(event) {
      this.id = event.id;

      await this.$refs.dialog.openDialog();
      this.startForm(event);
    },

    startForm(event) {
      this.$refs.name.handleInput(event.name);
      this.$refs.city.handleInput(event.city);
      this.$refs.district.handleInput(event.district);
    },

    close() {
      this.$refs.dialog.closeDialog();
    },

    displayAlert,
  },
};
</script>

<style></style>

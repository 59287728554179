<template>
  <v-container>
    <div
      class="d-flex align-space-between align-sm-center justify-space-between flex-column flex-sm-row mb-8"
    >
      <header-view :title="'Escolas'" class="mb-4 mb-sm-0" />

      <v-btn
        color="primary"
        class="black--text"
        depressed
        @click="createSchool()"
      >
        Criar nova
      </v-btn>
    </div>

    <filter-input
      class="mb-8"
      :dataFilters="require('@/assets/league/filters/schools.json')"
    />

    <custom-table
      :headers="headers"
      :items="schools"
      :pagination="pagination"
      :loading="loading"
      @update:pagination="handlePagination($event)"
    >
      <template v-slot:[`item.createdAt`]="{ item }">
        {{ formatDate(item.createdAt) }}
      </template>

      <template v-slot:[`item.updatedAt`]="{ item }">
        {{ formatDate(item.updatedAt) }}
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-btn class="rounded-lg" icon small>
          <v-icon @click="editSchool(item)">mdi-pencil</v-icon>
        </v-btn>
      </template>
    </custom-table>

    <CreateSchool ref="createSchool" @success="getData()" />

    <EditSchool ref="editSchool" @success="getData()" />
  </v-container>
</template>

<script>
import { getSchools } from "@/services/league/schools.js";
import { formatDate } from "@/utils";
import CreateSchool from "@/components/league/schools/CreateSchool.vue";
import EditSchool from "@/components/league/schools/EditSchool.vue";

export default {
  data() {
    return {
      loading: true,
      search: "",
      pagination: {
        page: 0,
        size: 20,
        total: 1,
      },
      headers: [
        {
          text: "Nome",
          value: "name",
        },
        {
          text: "Cidade",
          value: "city",
        },
        {
          text: "Bairro",
          value: "district",
        },
        {
          text: "Data de criação",
          value: "createdAt",
        },
        {
          text: "Data de atualização",
          value: "updatedAt",
        },
        {
          value: "actions",
        },
      ],
      schools: [],
    };
  },

  components: {
    CreateSchool,
    EditSchool,
  },

  beforeMount() {
    this.getData();
  },

  watch: {
    ["$route.query"](value) {
      this.handleQuery(value);
    },
  },

  methods: {
    async getData(filter = {}) {
      try {
        const payload = {
          page: this.pagination.page,
          size: this.pagination.size,
          ...filter,
        };

        await getSchools(payload).then((res) => {
          this.schools = res.data;
          this.pagination.total = res.total;
        });
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },

    createSchool() {
      this.$refs.createSchool.handleDialog();
    },

    editSchool(value) {
      this.$refs.editSchool.handleDialog(value);
    },

    handleQuery(value) {
      this.loading = true;
      this.pagination.page = 0;
      this.getData(value);
    },

    handlePagination(event) {
      this.pagination.page = event;
      this.getData();
    },

    formatDate,
  },
};
</script>

<style></style>
